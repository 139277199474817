// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-blogs-template-js": () => import("./../../../src/templates/blogsTemplate.js" /* webpackChunkName: "component---src-templates-blogs-template-js" */),
  "component---src-templates-bulk-cabin-template-js": () => import("./../../../src/templates/bulkCabinTemplate.js" /* webpackChunkName: "component---src-templates-bulk-cabin-template-js" */),
  "component---src-templates-cabin-template-js": () => import("./../../../src/templates/cabinTemplate.js" /* webpackChunkName: "component---src-templates-cabin-template-js" */),
  "component---src-templates-info-template-js": () => import("./../../../src/templates/infoTemplate.js" /* webpackChunkName: "component---src-templates-info-template-js" */),
  "component---src-templates-region-template-js": () => import("./../../../src/templates/regionTemplate.js" /* webpackChunkName: "component---src-templates-region-template-js" */)
}

